:root {
  --color-start: #44CDFF;
  --color-end: #0051FF;
}

.box {
  border: none;
  cursor: pointer;
  background-size: 300% 100%;
  background-image: linear-gradient(90deg, var(--color-start), var(--color-end),var(--color-start), var(--color-end));
  animation: animateBg 14s linear infinite;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: animateBg 14s linear infinite;
}

@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}