:root {
  --button-color-start: #FF229F;
  --button-color-end: #FF7300;
}

.button {
  position: relative;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  background-size: 300% 100%;
  background-image: linear-gradient(
    90deg,
    var(--button-color-start),
    var(--button-color-end),
    var(--button-color-start),
    var(--button-color-end)
  );
  animation: animateBg 8s linear infinite;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: Syne;
  text-transform: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 5px;
  background-color: #fff;
  background-image: linear-gradient(315deg, #fff 0%, #fff 74%);
  transition: all 0.3s ease;
}

.button:hover {
  color: #000;
}
.button:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}
.button:active {
  top: 2px;
}

@keyframes animateBg {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
