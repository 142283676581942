.section{
  text-align:left;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

.fp-credits {
  display: none !important;
}